<template>
<div>

     <ui-row
        large
        spaceEvenly
        stackMobile
        alignTop
        padding
    >
        <ui-col>
            <ui-p
                black
            >
                Please note that this calendar is read only and as it stands meant as a reference only incase of issues with the app.
            </ui-p>
        </ui-col>
    </ui-row>

    <ui-row
        large
        spaceEvenly
        stackMobile
        alignTop
        padding
    >
        <ui-col
            flexStart
            large
            mobile
        >
            <v-calendar
            class="custom-calendar max-w-full"
            :masks="masks"
            :attributes="attributes"
            disable-page-swipe
            is-expanded
            ref="calendar"
            
            >
            <template v-slot:day-content="{ day, attributes }">
                <div @click="onDateSelected(day)" >
                <span>{{ day.day }}</span>
                    <div  class="event-container">
                        <p
                        v-for="attr in attributes"
                        :key="attr.key"
                        :style="`background: ${attr.customData.activeColor}`"
                        class="event"
                        >
                        {{ attr.customData.title }}
                        </p>
                    </div>
                </div>
            </template>
            </v-calendar>
        </ui-col>

        <ui-col
            flexStart
            small
            mobile
        >

            <ui-h
                small
                black
            >
                Showing events for <br> {{moment(activeDate).format('dddd MMMM Do YYYY')}}
            </ui-h>

            <div 
                class="events"
                v-if="activeDayEvents.length > 0"
            >
                <div 
                    v-for="event in activeDayEvents"
                    :key="event.collectionid"
                    class="events__event"
                    :style="`background: ${event.activeColor}`"
                >
                    <ui-h>
                        {{event.title}} @ {{moment(event.startTime).format('HH:mma')}}
                    </ui-h>

                    <ui-p
                        class="events__dogs"
                    >
                        <Span 
                            v-for="dog in event.dogs"
                            :key="dog.collectionid"
                        >
                        {{getDogDetails(dog.collectionid).name}}
                        </Span>
                    </ui-p>

                </div>
	        </div>

            <div
                v-else
                class="no-events"
            >
                <ui-p
                    color="#cccccc"
                >
                    No events 
                </ui-p>
            </div>
        </ui-col>
    </ui-row>

</div>
</template>


<script>
export default {
    methods: {

		getDogDetails(collectionid) {
			return this.$store.getters.getterDogProfile(collectionid)
		},

        async onGoToToday() {
            // Get the calendar ref
            const calendar = this.$refs.calendar;
            await calendar.move(new Date());

			this.activeDate = new Date()
        },

		onDateSelected(day) {
			this.activeDate = new Date(day.date)
		},

		// We dont want to populate the events collection with all the days of
		// a boarding event so lets create a seperate array and merge as this is purely
		// for the calendar anyway
		boardingEvents() {
			let events = this.$store.getters.getterGetCurrentEvents.filter(data => 
				data.type == 'boarding'
			)

			let boardingDates = []

			if(events.length > 0) {
				for (let i = 0; i < events.length; i++) {
					let startDate = new Date(events[i].startTime);
					let endDate = new Date(events[i].endTime);

					if (this.moment(this.activeDate).isSame(startDate, 'day')) {
                        boardingDates.push(events[i]) 
					} 

					if (this.moment(this.activeDate).isBetween(startDate, endDate)) {
						boardingDates.push(events[i])  
					}
				}
			}

			return boardingDates
		},
    },

    computed: {
        attributes() {
            let calendarEvents = this.$store.getters.getterGetCurrentEvents;
            let mapAttributes = [];

            calendarEvents = calendarEvents.sort((a, b) => {  
				return new Date(a.startTime).getHours() - new Date(b.startTime).getHours()
			});

            calendarEvents.forEach((event) => {

                let newDates = event.eventDate

                if(event.type == 'boarding') {
                    newDates = { start: event.startTime, end: event.endTime }
                } 

                mapAttributes.push({
                    key: event.collectionid,
                    customData: {
                        eventDate: this.moment(event.eventDate).toDate(),
                        startTime: event.startTime,
                        title: event.title,
                        class: "bg-red-600 text-white",
						activeColor: event.activeColor
                    },
                    dates: newDates,
                });
            });

			return mapAttributes

        },

		activeDayEvents() {  
			let events = []
			let customEventsWithTimes = []
			let allCustomEvents = []
			
			// For custom events we need to create an entry for each time slot that the custom event has
			customEventsWithTimes = this.$store.getters.getterGetCurrentEvents.filter(data => 
				data.eventMonth == this.activeDate.getMonth()
				&& data.eventDay == this.activeDate.getDate()  
				&& data.type == 'custom'
			)

			// collectionid, eventDate, dogs, type, activeColor, notes, title,
			customEventsWithTimes.forEach((data, index) => {
				data.times.forEach((data1, index1) => {
					allCustomEvents.push({
						collectionid: data.collectionid,
						eventDate: new Date(data.eventData),
						startTime: new Date(data1.startTime),
						endTime: new Date(data1.endTime),
						dogs: data.dogs,
						type: data.type,
						activeColor: data.activeColor,
						notes: data.notes,
						title: data.title,
						times: data.times,

						event: {
							...data
						}
					})
				})
			})

            let boarding = this.$store.getters.getterGetCurrentEvents.filter(data => 
				data.type == 'boarding'
			)

			let boardingDates = []

			if(boarding.length > 0) {
				for (let i = 0; i < boarding.length; i++) {
					let startDate = new Date(boarding[i].startTime);
					let endDate = new Date(boarding[i].endTime);

					if (this.moment(this.activeDate).isSame(startDate, 'day')) {
                        boardingDates.push(boarding[i]) 
					} 

					if (this.moment(this.activeDate).isBetween(startDate, endDate)) {
						boardingDates.push(boarding[i])  
					}
				}
			}
			
			let calendarEvents = this.$store.getters.getterGetCurrentEvents.filter(data => 
				data.eventMonth == this.activeDate.getMonth()
				&& data.eventDay == this.activeDate.getDate()  
				&& data.type != 'custom'
				&& data.type != 'boarding'
			)

		
            events = calendarEvents
            events = [...events, ...allCustomEvents, ...boardingDates]
			
			events = events.sort((a, b) => {  
				return new Date(a.startTime).getHours() - new Date(b.startTime).getHours()
			});

			return events
		},
    },

    data() {
        return {
            masks: {
                weekdays: "WWW",
            },

			selectedEventType: 0,

			activeDate: new Date()
        };
    },
};
</script>

<style lang="scss">

.is-not-in-month {
    background: #eeeeee !important;
}

.event {
    color: #ffffff;
    padding: 5px;
    font-size: 11px;
    border-radius: 2px;
}

.events {
	color: white;
	padding: 5px;
	font-size: 11px;

    &__event {
        padding: 20px;
        color: #ffffff;
        margin-bottom: 10px;
    }

    &__header {
        color: #ffffff;
    }

    &__dogs {
        color: #ffffff;
    }
}

 .is-today {
    background: green;
}

.event-container {
    height: 150px;
    overflow: scroll;

    &:hover {
        cursor: pointer;
    }
}

.vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    border: 1px solid #dddddd;
    background-color: white;
    
}

 .weekday-position-6, .weekday-position-7 {
    background: #eeeeee;
}



 .custom-calendar.vc-container {
    
    border: 1px solid #dddddd;

    border-radius: 0;
    max-width: 100%;

    & .vc-header {
        background-color: #f1f5f8;
        padding: 10px 0;
    }
    & .vc-weeks {
        padding: 0;
    }
    & .vc-weekday {
        background-color: var(--weekday-bg);
        border-bottom: var(--weekday-border);
        border-top: var(--weekday-border);
        padding: 5px 0;
    }
    & .vc-day {
        padding: 0 5px 3px 5px;
        text-align: left;
        height: var(--day-height);
        min-width: var(--day-width);
        &.weekday-1,
        &.weekday-7 {
            background-color: #eff8ff;
        }
        &:not(.on-bottom) {
            border-bottom: var(--day-border);
            &.weekday-1 {
                border-bottom: var(--day-border-highlight);
            }
        }
        &:not(.on-right) {
            border-right: var(--day-border);
        }
    }
    & .vc-day-dots {
        margin-bottom: 5px;
    }
}
</style>